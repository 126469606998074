// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
global.jQuery = global.$ = require('jquery')
require("jquery-mask-plugin")
require("popper.js")
require("bootstrap-3-typeahead")
require("bootstrap/dist/js/bootstrap")
// require("jquery.metisMenu")
global.MetisMenu = require("metismenujs")
require("pace.min")
require("peity")
require("jquery.slimscroll.min")
require('footable')
require("datatables.min")
require("bootstrap-datepicker")
require("summernote.min.js")
require("select2")

import 'select2/dist/css/select2.min.css'

global.toastr = require("toastr")

// custom
require("custom/inspinia")

console.log('custom/inspinia')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$.jMaskGlobals.watchDataMask = true;

$.fn.datepicker.dates['pt-BR'] = {
    days: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    daysMin: ["Do", "Se", "Te", "Qua", "Qui", "Sex", "Sa"],
    months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthsShort: ["Jan", "Fev", "Mar", "Abr", "Maio", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: "Hoje",
    clear: "Limpar",
    format: "dd/mm/yyyy",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 0
};